import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 5-5-5-5\\@75% 1RM`}</p>
    <p>{`DB Rows 8-8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`5 Ring Muscle Ups`}</p>
    <p>{`10 Push Press (115/75)`}</p>
    <p>{`15 GHD Situps`}</p>
    <p>{`20/15 Calorie Bike`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`September 1st is the last day to get your teams registered for True
Grit 6 and get your shirts on the day of the competition.  Get your 4
person (2 men/2 women) team signed up today!  RX and Scaled teams will
battle it out for the 6th year at the Ville for cash prizes and other
goodies.  Competition date is September 15th. If you’d like to compete
but don’t have a team we have a signup sheet by the white board.  If
you’d like to help judge please add your name to the sheet.  We still
need several more judges.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      